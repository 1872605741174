import React, { FC } from 'react';

function Footer() {

    const year = new Date().getFullYear();
    const scrollTop = () => document.getElementById('top').scrollIntoView({ behavior: 'smooth' });

    const socialLinks = [
        {
            "title": "LinkedIn",
            "url": "https://www.linkedin.com/in/michaelwitty",
            "icon": "icon-linkedin-squared"
        },
        {
            "title": "Github",
            "url": "https://github.com/michaelwitty",
            "icon": "icon-git-squared"
        },
        {
            "title": "aboutme",
            "url": "http://about.me/michaelwitty/",
            "icon": "icon-info-circled"
        }
    ]

    return (
        <>
        <footer>
            <div id="footer-social-links" className="text-center">
                <h2>Connect</h2>
                <div className="hr"></div>
                    <ul className="list-inline">
                        {socialLinks.map(link =>
                            <li key={link.title} className="list-inline-item">
                                <a className="icon" href={link.url} rel="nofollow" target="_blank" title={link.title}>
                                    <span className={link.icon}></span>
                                </a>
                            </li>
                        )}
                </ul>
            </div>
            <section className="footer">
                    <div className="p-3">&copy; Abstract Method 2010 - {year}</div>
            </section>
        </footer>
        <button type="button" id="scroll-top" className="btn btn-primary d-print-none" onClick={scrollTop}><span className="visually-hidden">top</span><span className="icon-up-open"></span></button>
        </>
    );
} export default Footer