import React, { FC, useState, useEffect } from 'react';
import { useDocumentTitle } from "../hooks/setDocumentTitle";

function Home() {

    useDocumentTitle("Abstract Method");

    const LoadingStates = {
        Loading: "Loading",
        Error: "Error",
        Loaded: "Loaded"
    }

    const [loadingState, setLoadingState] = useState(LoadingStates.Loading);
    const [latestPosts, setLatestPosts] = useState();

    useEffect(() => {
        fetch('api/contentlatest', {
            method: "GET"
        }).then(response => {
            if (!response.ok) {
                throw `Error : ${response.status} ${response.statusText} ${response.json}`;
            }
                return response.json()
        })
        .then(json => {
            setLatestPosts(json);
            setLoadingState(LoadingStates.Loaded);
        }).catch(err => {
            setLoadingState(LoadingStates.Error);
        });
    }, []);
    // TODO move to seperate component
    function LatestPosts() {
        switch (loadingState) {
            case LoadingStates.Loading:
                return (<div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>);
            case LoadingStates.Error:
                return (<div className="text-center text-danger fw-bold">Error</div>);
            case LoadingStates.Loaded:
                return (<>{
                    latestPosts.map(blogPost =>
                        <article className="text-center">
                            <h3>
                                <a className="text-decoration-underline" href={blogPost.url}>{blogPost.name}</a>
                            </h3>
                            <p>{blogPost.summary}</p>
                        </article>
                    )
                }</>);
        }
    }

    return (
        <>
            <section id="hero">
                <header className="text-center m-4">
                    <h1 className="font-thin">Specialist in <span className="font-semibold">.NET</span> web development, <span className="font-semibold">Umbraco CMS</span>, and a wide range of other technologies.</h1>
                </header>
                <hr />
                <div className="row">
                    <div className="text-center col-md-4 col-12">
                        <h2>Design</h2>
                        <p>I gained a degree in <a href="http://design.michaelwitty.com" target="_blank" title="design.michaelwitty.com">Product Design Engineering</a> before doing my Masters in Computer and Internet Technologies. This gives me a good balance of creative and technical ability.</p>
                    </div>
                    <div className="text-center col-md-4 col-12">
                        <h2>Server-side</h2>
                        <p>I started out building web applications with .NET and Java. I'm always interested in the technical aspect of a development build as well as the aesthetic. I believe a good solid code base is essential to any project.</p>
                    </div>
                    <div className="text-center col-md-4 col-12">
                        <h2>Front-end</h2>
                        <p>It used to be the case that all you needed on the client side was a bit of spit and polish, but no more. I aim to keep my front end ability as up to date as my general programming and apply the same principles and good practices.</p>
                    </div>
                </div>
            </section>
            <hr />
            <section id="skills">
                <header className="text-center h2">
                    What I can do
                </header>
                <div className="row">
                    <div className="text-center col-md-3 col-6">
                        <span className="center-block icon icon-circled icon-html5"></span>
                        <h3>Web</h3>
                        <p>Current web standards and best practices, <a href="/blog/category/css3" title="Blog">CSS3</a>, <a href="/blog/category/html5" title="Blog">HTML5</a>, <a href="/blog/category/jquery" title="Blog">jQuery</a>, <a href="/blog/category/AngularJs" title="Blog">Angular JS</a></p>
                    </div>
                    <div className="text-center col-md-3 col-6">
                        <span className="center-block icon icon-circled icon-mobile"></span>
                        <h3>Mobile</h3>
                        <p><a href="/blog/category/responsive" title="Blog">Responsive websites</a>, <a href="/blog/category/mobile" title="Blog">Mobile Applications</a>, <a href="/blog/category/mobile" title="Blog">Mobile Templates</a></p>
                    </div>
                    <div className="text-center col-md-3 col-6">
                        <span className="center-block icon icon-circled icon-gamepad"></span>
                        <h3>Games</h3>
                        <p><a rel="noopener noreferrer" href="http://www.michaelwitty.com/games" target="_blank">Game development</a> using <a href="/blog/category/unity%203d" title="Blog">Unity 3D</a>, mobile gaming, web based games</p>
                    </div>
                    <div className="text-center col-md-3 col-6">
                        <span className="center-block icon icon-circled icon-code"></span>
                        <h3>Code</h3>
                        <p><a href="/blog/category/visual%20studio" title="Blog">Visual Studio</a>, <a href="/blog/category/sql%20server" title="Blog">SQL Server</a>, <a href="/blog/category/umbraco" title="Blog">CMS Systems</a>, Web Applications, GIT, SVN, C#, OOP</p>
                    </div>
                </div>
            </section>
            <hr />
            <section id="work">
                <header className="text-center h2">
                    Latest Posts
                </header>
                <LatestPosts />
            </section>
        </>
    );
} export default Home