import React, { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function BlogPost() {

    const LoadingStates = {
        Loading: "Loading",
        Error: "Error",
        Loaded: "Loaded"
    }

    const [loadingState, setLoadingState] = useState(LoadingStates.Loading);
    const [blogPost, setBlogPost] = useState();

    document.title = "Blog | Abstract Method";

    let { slug } = useParams();

    useEffect(() => {
        fetch('api/contentpage?slug=' + slug, {
            method: "GET"
        }).then(response => {
            if (!response.ok) {
                throw `Error : ${response.status} ${response.statusText} ${response.json}`;
            }
            return response.json()
        })
        .then(json => {
            setBlogPost(json);
            setLoadingState(LoadingStates.Loaded);
            document.title = `${json.name} | Abstract Method`;
        }).catch(err => {
            setLoadingState(LoadingStates.Error);
        });
    },[slug]);
    // TODO DRY move to seperate component
    function BlogPost() {
        switch (loadingState) {
            case LoadingStates.Loading:
                return (<div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>);
            case LoadingStates.Error:
                return (<div className="text-center text-danger fw-bold">Error</div>);
            case LoadingStates.Loaded:
                return (<>
                    <h1>{blogPost.name}</h1>
                    <ul className="tags list-inline">
                        {blogPost.tags.split(',').map((tag) =>
                            <li className="list-inline-item"><span className="icon-tag"></span><a href={'/blog/category/' + tag.toLowerCase()}>{tag}</a></li>
                        )}
                    </ul>
                    <div dangerouslySetInnerHTML={{ __html: blogPost.content }} />
                    {blogPost.prev ? <a href={blogPost.prev} className="pager prev"><span className="icon-left-open"></span>Prev</a> : null}
                    {blogPost.next ? <a href={blogPost.next} className="pager next"><span className="icon-right-open"></span>Next</a> : null}
                </>
                );
        }
    }

    return (
        <BlogPost />
    );
} export default BlogPost