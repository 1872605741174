import React, { FC, useState, useEffect } from "react";
import { useDocumentTitle } from "../hooks/setDocumentTitle";
function StockViz() {

    useDocumentTitle("Stock Market Visualization | Abstract Method");

    const LoadingStates = {
        Loading: "Loading",
        Error: "Error",
        Loaded: "Loaded"
    }

    const [loadingState, setLoadingState] = useState(LoadingStates.Loading);
    const [stockPrices, setStockPrices] = useState([]);

    useEffect(() => {

        var url = 'api/StockPrices';

        fetch(url, {
            method: "GET"
        }).then(response => {
            if (!response.ok) {
                throw `Error : ${response.status} ${response.statusText} ${response.json}`;
            }
            return response.json()
        })
        .then(json => {
            setStockPrices(json);
            setLoadingState(LoadingStates.Loaded);
        }).catch(err => {
            setLoadingState(LoadingStates.Error);
        });
    }, []);
    // TODO move to top-level
    function StockResults() {
        switch (loadingState) {
            case LoadingStates.Loading:
                return (<div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>);
            case LoadingStates.Error:
                return (<div className="text-center text-danger fw-bold">Error</div>);
            case LoadingStates.Loaded:
                return (<>
                    {stockPrices.map(item =>
                        <div key={item.code} className="col-12 col-sm-6 col-lg-3 text-center">
                            <h4>{item.code}</h4>
                            <h5>{item.name}</h5>
                            <p>{item.close} <span style={{ color: item.percentChange > 0 ? 'green' : 'red' }}>({Number.parseFloat(item.percentChange).toPrecision(2)}%)</span></p>
                            <svg>
                                <circle cx="50%" cy="50%" r="40" style={{ margin: 'auto', stroke: item.percentChange > 0 ? 'green' : 'red', strokeWidth: ((Math.abs(item.percentChange)) + 5), fill: 'none' }} />
                            </svg>
                        </div>
                    )}
                </>);
        }
    }

    return <>
        <div className="row">
            <h1>Stock Price Visualization</h1>
            <p>In 2007 the aim of my dissertation research was to analyse how investors collect and use typical market
                indicators and investigate the ways in which technology can be used to enable
                more informed time critical decisions.</p>
            <p>The research focused not on predicting the market but providing better tools to help
                investors make decisions based on the market conditions. Current and emerging standards at the time such as XML and Web 2.0 were investigated to provide solutions to some of the common
                problems of data retrieval, representation, organisation and manipulation. If you want to read the full report you can <a href="https://stwebprodsouthuk001.blob.core.windows.net/stblob-web-prod-southuk-001/Report_12042014.pdf" target="_blank" download="test.pdf" className="text-decoration-underline">download it here.</a></p>
            <p>This is just an example visualisation using SVG and React with a small random set of UK stocks, price data is not necessarily current but end of day or delayed by 15 mins.</p>
            <StockResults />
        </div>

    </>
} export default StockViz