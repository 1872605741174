import React, { FC } from "react"
import { useDocumentTitle } from "../hooks/setDocumentTitle";

function Projects() {
    useDocumentTitle("Projects | Abstract Method");

    const projects = [
        {
            "title": "Stock Price Viz",
            "url": "/projects/stock-viz",
            "imageUrl": "images/stockviz-icon.png",
            "summary": "Back in 2007 my MSc Dissertation was focused on new ways to represent market data using SVG, XForms and XML document storage with Apache Xindice. Most of the tech is retired now but I have re-created one of the results here with React and a Google Sheets powered json data feed."
        },
        {
            "title": "Personal Site",
            "url": "https://www.michaelwitty.com",
            "imageUrl": "images/mw-icon.png",
            "summary": "Really I just wanted to ensure I reserved my own domain name, but I thought I should at least use it. So I developed a very simple Angular SPA to play around with different frameworks. I may add more actual content and functionality in future."
        },
        {
            "title": "Art Site",
            "url": "https://art.michaelwitty.com",
            "imageUrl": "images/art-icon.png",
            "summary": "On occasion I have painted abstract and contemporary pieces, usually of nothing in particular, just whatever I feel like. I developed this simple Blazor web assembly site to showcase some of my past work and try out Blazor."
        },
        {
            "title": "Design Site",
            "url": "https://design.michaelwitty.com",
            "imageUrl": "images/design-icon.png",
            "summary": "I Studied Product Design Engineering before changing career direction to become a software engineer. This is another Blazor web assembly site to showcase some of the work I did whilst studying."
        },
        {
            "title": "Bitmigo",
            "url": "https://www.bitmigo.com",
            "imageUrl": "images/music-icon.png",
            "summary": "I love electronic music and experimenting with sound using mainly software such as Ableton Live. Bitmigo is one of my side projects that I pursue as a hobby where I play around with sound and try to create interesting tracks. This React SPA has some samples and links to listen to my work."
        },
        {
            "title": "Witty Crafts Design",
            "url": "https://www.wittycraftsdesign.com/tools/granny-square-colour-picker",
            "imageUrl": "images/wcd-icon.png",
            "summary": "My wife loves crochet and she creates digital patterns for sale. This site was started as a small link tree mainly for Instagram. It also features some tools such as a pattern maker to help select colour combinations. Developed as a React SPA with .NET Azure serverless functions."
        },
        {
            "title": "Abstract Method",
            "url": "/",
            "imageUrl": "images/am-icon.png",
            "summary": "Originally part of a multi-tenant Umbraco install written in .NET framework, MVC front end and SQL server. It however became a bit monolithic and I wanted everything on .NET Core using more modern front end frameworks. It is now a React SPA using .NET Azure serverless functions to fetch content from Azure Cosmos and Azure Search integration."
        },
        {
            "title": "Recipes",
            "url": "https://aml-recipemanager.azurewebsites.net/Recipe",
            "imageUrl": "images/52-icons.png",
            "summary": "A more traditional .NET 6, Razor Pages Web App with Azure SQL and Azure Search integration, this site helps me collect and organise all my recipes. It has functionality to manage recipes, plan meals and create shopping lists, although much of this is behind a Azure AD login."
        },
        {
            "title": "Just Toast",
            "url": "https://www.michaelwitty.com/games/just-toast",
            "imageUrl": "images/justtoast-icon.png",
            "summary": "My first Unity project, a little 2D shooter game that I made back in 2012. It has been built so it can run in the browser but has touch controls so would also work on mobile."
        }
    ];

    return (
        <div className="row">
            <h1>Projects</h1>
            {projects.map(project =>
                <div key={project.title} className="card border-0 col-12 col-sm-6 col-lg-3 mt-2">
                    <img src={project.imageUrl} alt={project.title} className="img-fluid rounded-4 w-50 m-auto" />
                    <div className="card-body text-center">
                        <div className="card-title">{project.title}</div>
                        <p className="card-text text-start">{project.summary}</p>
                        <a href={project.url} className="btn btn-primary stretched-link" target="_blank"><span className="fw-bold text-decoration-underline">Find out more <span className="icon-link-ext"></span></span></a>
                    </div>
                </div>  
            )}
        </div>        
        );
} export default Projects