import React, { FC, useState, useEffect } from 'react';

interface SocialFeedProps {
    feedType: string;
    heading: string;
} 

function SocialFeed(props: SocialFeedProps) {

    const LoadingStates = {
        Loading: "Loading",
        Error: "Error",
        Loaded: "Loaded"
    }

    const [loadingState, setLoadingState] = useState(LoadingStates.Loading);
    const [feedItem, setFeedItem] = useState();

    useEffect(() => {

        var url = `api/SocialFeed?feedType=${props.feedType}`;

        fetch(url, {
            method: "GET"
        }).then(response => {
            if (!response.ok) {
                throw `Error : ${response.status} ${response.statusText} ${response.json}`;
            }
            return response.json()
        })
            .then(json => {
                setFeedItem(json);
                setLoadingState(LoadingStates.Loaded);
            }).catch(err => {
                setLoadingState(LoadingStates.Error);
            });
    }, []);
    // TODO move to top-level
    function FeedItem() {
        switch (loadingState) {
            case LoadingStates.Loading:
                return (
                <div className="panel-body text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>);
            case LoadingStates.Error:
                return (<div className="panel-body text-center text-danger fw-bold">Error</div>);
            case LoadingStates.Loaded:
                return (
                    <>
                        <div className="panel-body">
                            <p>{feedItem.title}</p>
                        </div>
                        <div className="panel-footer">
                            <a href={feedItem.link} className="btn btn-primary" rel="nofollow" target="_blank">More <span classBane="icon icon-link-ext"></span></a>
                        </div>
                    </>
                );
        }
    }

    return (
        <div className="panel panel-default text-center mb-2">
            <div className="panel-heading">
                <h4>{props.heading}</h4>
            </div>
            <FeedItem />
        </div>
    );

}export default SocialFeed