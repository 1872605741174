import React, { FC } from 'react'

function ReadingList() {

    const items = [
        {
            "title": "97 Things Every Programmer Should Know",
            "url": "https://amzn.to/2T7bTV6",
            "imageUrl": "https://images-eu.ssl-images-amazon.com/images/I/51mWqv-7TtL._SL160_.jpg"
        },
        {
            "title": "Clean Code: A Handbook of Agile Software Craftsmanship",
            "url": "https://amzn.to/2R2EFVd",
            "imageUrl": "https://images-eu.ssl-images-amazon.com/images/I/51d1qVhmAmL._SL160_.jpg"
        },
        {
            "title": "Code Complete (Developer Best Practices)",
            "url": "https://amzn.to/2U9oEyP",
            "imageUrl": "https://images-eu.ssl-images-amazon.com/images/I/51MEZDyKvZL._SL160_.jpg"
        },
        {
            "title": "Design Patterns: Elements of Reusable Object-Oriented Software",
            "url": "https://amzn.to/2T5ibVv",
            "imageUrl": "https://images-eu.ssl-images-amazon.com/images/I/51kuc0iWoKL._SL160_.jpg"
        },
        {
            "title": "Patterns of Enterprise Application Architecture",
            "url": "https://amzn.to/2T6nLa2",
            "imageUrl": "https://images-eu.ssl-images-amazon.com/images/I/51X%2Br%2BOdV3L._SL160_.jpg"
        },
        {
            "title": "The Mythical Man-Month",
            "url": "https://amzn.to/2Hprgqy",
            "imageUrl": "https://images-eu.ssl-images-amazon.com/images/I/51xCKsOn83L._SL160_.jpg"
        },
        {
            "title": "The Pragmatic Programmer: From Journeyman to Master",
            "url": "https://amzn.to/2W8SYLv",
            "imageUrl": "https://images-eu.ssl-images-amazon.com/images/I/41uPjEenkFL._SL160_.jpg"
        }
    ]

return (
    <div className="row">
        <h1>Reading List</h1>
            {items.map(item =>
                <div className="col text-center mt-2">
                    <a href={item.url} rel="nofollow" target="_blank" title={item.title}>
                        <img src={item.imageUrl} alt={item.title} />
                    </a>
                </div>
            )}
    </div>);
} export default ReadingList