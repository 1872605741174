import React, { FC, useState } from 'react';
import { useDocumentTitle } from "../hooks/setDocumentTitle";
import {
    Link,
} from "react-router-dom";

function Search() {

    useDocumentTitle("Search | Abstract Method");

    const LoadingStates = {
        Waiting: "Waiting",
        Loading: "Loading",
        Error: "Error",
        Loaded: "Loaded"
    }

    const [loadingState, setLoadingState] = useState(LoadingStates.Waiting);
    const [blogPosts, setBlogPosts] = useState();
    const [keyword, setKeyword] = useState();

    function handleKeywordChange(event) {
        setKeyword(event.target.value);
    }

    function handleSubmit(event) {
        event.preventDefault();
        var url = 'api/search?keyword=' + encodeURIComponent(keyword);
        setLoadingState(LoadingStates.Loading);
        fetch(url, {
            method: "GET"
        }).then(response => {
            if (!response.ok) {
                throw `Error : ${response.status} ${response.statusText} ${response.json}`;
            }
            return response.json()
        })
        .then(json => {
            setBlogPosts(json);
            setLoadingState(LoadingStates.Loaded);
        }).catch(err => {
            setLoadingState(LoadingStates.Error);
        });
    }
    // TODO DRY move to seperate component
    function BlogPosts() {
        switch (loadingState) {
            case LoadingStates.Loading:
                return (
                    <div className="text-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>);
            case LoadingStates.Error:
                return (<div className="text-center text-danger fw-bold">Error</div>);
            case LoadingStates.Loaded:
                return (<>
                    <div className="fs-3 mb-3">Showing { blogPosts.length } Results</div>
                    {blogPosts.map(blogPost => {
                        const date = new Date(blogPost.date);
                        return (
                            <article>
                                <time dateTime={blogPost.date}>
                                    <span className="year">{date.getFullYear()}</span>
                                    <span className="month">{date.toLocaleString("en-GB", { month: "short" })}</span>
                                    <span className="day">{date.getDate()}</span>
                                </time>
                                <h3>
                                    <Link to={`${blogPost.url}`}>{blogPost.name}</Link>
                                </h3>
                                <p>{blogPost.summary}</p>
                                <ul className="tags list-inline">
                                    {blogPost.tags.split(',').map((tag) =>
                                        <li className="list-inline-item"><span className="icon-tag"></span><a href={'/blog/category/' + tag.toLowerCase()}>{tag}</a></li>
                                    )}
                                </ul>
                                <hr />
                            </article>
                        )
                    }
                    )}</>);
        }
    }

    return (
        <>
            <div className="row">
                <h1>Search</h1>
                <form onSubmit={handleSubmit} method="post">
                    <div class="input-group mb-3">
                        <input name="Keyword" onChange={handleKeywordChange} type="text" className="form-control" />
                        <button className="btn btn-primary rounded-end" type="submit">
                            <span className="icon-search"></span>
                        </button>
                    </div>
                </form>
                <BlogPosts />
            </div>
        </>
    );
} export default Search