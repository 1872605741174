import React, { FC } from "react";
import SocialFeed from "./SocialFeed";
import { useDocumentTitle } from "../hooks/setDocumentTitle";

function About() {
    useDocumentTitle("About | Abstract Method");

    return (
        <div className="row">
            
            <div className="col-12 col-md-9 order-md-last">
                <h1>About</h1>
                <p>I’m a Senior Developer with a wide range of experience working in consultancy based positions since 2007. I have knowledge of all aspects of the software development cycle using a range of different technologies and methods.</p>
                <p>My main expertise lay with Microsoft web technologies such as <a href="/blog/category/c#" title="Blog">C#</a>, <a href="/blog/category/aspnet" title="Blog">ASP.Net</a> and <a href="/blog/category/sql%20server" title="Blog">MS SQL</a>, specifically within CMS implementations such as <a href="/blog/category/umbraco" title="Blog">Umbraco</a> but also in custom web applications and general programming.</p>
            </div>
            <div className="col-12 col-md-3 order-md-first">
                <div className="panel-group">
                    <SocialFeed feedType="2" heading="What I am listening to" />
                </div>
                <div className="panel-group">
                    <SocialFeed feedType="5" heading="What I am drinking" />
                </div>
                <div className="panel-group">
                    <SocialFeed feedType="7" heading="What I am reading" />
                </div>
                <div className="panel-group">
                    <SocialFeed feedType="8" heading="What I am watching" />
                </div>
            </div>
        </div>
    );
} export default About