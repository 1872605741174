import React, { FC, useState } from 'react';
import Footer from './Footer';

function Layout({ children }) {

    const [navMenuCssClass, setNavMenuCssClass] = useState("wrapper");

    const navLinks = [
        {
            "title": "Home",
            "url": "/"
        },
        {
            "title": "About",
            "url": "about"
        },
        {
            "title": "Blog",
            "url": "blog"
        },
        {
            "title": "Projects",
            "url": "projects"
        },
        {
            "title": "Search",
            "url": "search"
        }
        //{
        //    "title": "Reading List",
        //    "url": "reading-list"
        //}
    ];

    function toggleNavMenu() {

        if (navMenuCssClass === "wrapper") {
            setNavMenuCssClass("wrapper toggled");
        } else {
            setNavMenuCssClass("wrapper");
        }
    }

    return (
        <div id="wrapper" className={navMenuCssClass}>
            <div id="page-content-wrapper">
                <div id="sidebar-wrapper">
                    <ul className="sidebar-nav">
                        {navLinks.map(link =>
                            <li key={link.title}><a className="text-decoration-none fs-5 p-2" href={link.url}>{link.title}</a></li>
                        )}
                    </ul>
                </div>
                <button id="menu-toggle" className="btn btn-primary" onClick={toggleNavMenu}>
                    <span className="visually-hidden">Menu</span>
                    <span className="icon-menu"></span>
                </button>
                <header id="top" className="text-center pt-3 mb-3">
                    <a href="/" title="Home" className="brand-header"><span className="font-semibold">Abstract</span> Method</a>
                    <ul id="header-nav" className="list-inline">
                        {navLinks.map(link =>
                            <li key={link.title} className="list-inline-item"><a className="text-decoration-none fs-5 py-2" href={link.url}>{link.title}</a></li>
                        )}
                    </ul>
                </header>
                <div className="content">
                    <div className="container">
                        {children}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
} export default Layout