import Home from "./components/Home";
import About from "./components/About";
import Blog from "./components/Blog";
import BlogPost from "./components/BlogPost";
import ReadingList from "./components/ReadingList";
import Projects from "./components/Projects";
import StockViz from "./components/StockViz";
import Search from "./components/Search"

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/index',
        element: <Home />
    },
    {
        path: '/about',
        element: <About />
    },
    {
        path: '/blog',
        element: <Blog />
    },
    {
        path: '/blog/category/:tag',
        element: <Blog />
    },
    {
        path: '/blog/:year',
        element: <Blog />
    },
    {
        path: '/blog/:year/:month',
        element: <Blog />
    },
    {
        path: '/blog/:year/:month/:slug',
        element: <BlogPost />
    },
    {
        path: '/reading-list',
        element: <ReadingList />
    },
    {
        path: '/projects',
        element: <Projects />
    },
    {
        path: '/projects/stock-viz',
        element: <StockViz />
    },
    {
        path: '/search',
        element: <Search />
    }
];

export default AppRoutes;
