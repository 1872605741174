import React, { FC, useState, useEffect } from 'react';
import { useDocumentTitle } from "../hooks/setDocumentTitle";
import { useParams } from 'react-router-dom';
import {
    Link,
} from "react-router-dom";

function Blog() {

    useDocumentTitle("Blog | Abstract Method");

    const LoadingStates = {
        Loading: "Loading",
        Error: "Error",
        Loaded: "Loaded"
    }

    const [loadingState, setLoadingState] = useState(LoadingStates.Loading);
    const [blogPosts, setBlogPosts] = useState();

    let { year, month, tag } = useParams();

    useEffect(() => {

        var url = 'api/content';
        var queryParts = [];

        if (year) {
            queryParts.push('year=' + year);
        }
        if (month) {
            queryParts.push('month=' + month);
        }
        if (tag) {
            queryParts.push('tag=' + tag);
        }

        if (queryParts.length > 0) {
            url = url + '?' + queryParts.join('&');
        }

        fetch(url, {
            method: "GET"
        }).then(response => {
            if (!response.ok) {
                throw `Error : ${response.status} ${response.statusText} ${response.json}`;
            }
            return response.json()
        })
        .then(json => {
            setBlogPosts(json);
            setLoadingState(LoadingStates.Loaded);
        }).catch(err => {
            setLoadingState(LoadingStates.Error);
        });
    },[year,month,tag]);
    // TODO move to seperate component
    function BlogPosts() {
        switch (loadingState) {
            case LoadingStates.Loading:
                return (<div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>);
            case LoadingStates.Error:
                return (<div className="text-center text-danger fw-bold">Error</div>);
            case LoadingStates.Loaded:
                return (<>
                    
                    {blogPosts.map(blogPost => {
                        const date = new Date(blogPost.date);
                        return (
                            <article>
                                <time dateTime={blogPost.date}>
                                    <span className="year">{date.getFullYear()}</span>
                                    <span className="month">{date.toLocaleString("en-GB", { month: "short" })}</span>
                                    <span className="day">{date.getDate()}</span>
                                </time>
                                <h3>
                                    <Link to={`${blogPost.url}`}>{blogPost.name}</Link>
                                </h3>
                                <p>{blogPost.summary}</p>
                                <ul className="tags list-inline">
                                    {blogPost.tags.split(',').map((tag) =>
                                        <li className="list-inline-item"><span className="icon-tag"></span><a href={'/blog/category/' + tag.toLowerCase()}>{tag}</a></li>
                                    )}
                                </ul>
                                <hr />
                            </article>
                        )
                    }
                    )}</>);
        }
    }

    return (
        <>
            <div className="row">
                <h1>Blog</h1>
                <BlogPosts />
            </div>
        </>
    );
} export default Blog